import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { motion, transform } from "framer-motion";
import Home from './components/Home';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Feedback from './components/Feedback';
import NavigationMenu from './components/NavigationMenu';
import BackgroundDesign from './components/BackgroundDesign';
import Loader from './components/Loader';
import { useState, useEffect } from 'react';
// import ReactGA from 'react-ga4';

// ReactGA.initialize("G-7L99NXGXBN");

function App() {

  // useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  // }, []);

  const [loaderHomeFlag, setLoaderHomeFlag] = useState(true);

  const flipTransition = {
    hidden: {
      transform: "rotateY(90deg)"
    },
    show: {
      transform: "rotateY(0deg)"
    }
  }

  const navTransition = {
    hidden: {
      // scale: 0.5,
      transform: "translate(-50%, -10%) scale(0.5)"
    },
    show: {
      // scale: 1,
      transform: "translate(-50%, 0) scale(1)"
    }
  }

  const fadeTransition = {
    hidden: {
      transform: "translate(45%, 0) rotateY(90deg)",
      opacity: 0
    },
    show: {
      transform: "translate(45%, 0) rotateY(0)",
      opacity: 1
    }
  }

  setTimeout(() => {setLoaderHomeFlag(false)}, 2500)

  return (
    <>
      <Router>
        <div className="outer">
          <motion.div className="home-loading"
           animate={ loaderHomeFlag ? {} : {
            // opacity:[1, 0, 0],
            scale:[1, 0, 0],
            borderRadius: ["0", "100%", "100%"],
            display: ["flex", "flex", "none"]
           }}
           transition={{
            ease: "easeIn",
            duration: 1
           }}>
            <Loader/>
          </motion.div>
          <BackgroundDesign />
          <motion.div className="main-container">
            <Home />
            <motion.div className="route-container"
              variants={fadeTransition}
              initial="hidden"
              animate="show"
              transition={{
                delay: 0.5
              }}>
              <Routes>
                <Route path="" element={<About flipTransition={flipTransition} />} />
                <Route path="/experience" element={<Experience flipTransition={flipTransition} />} />
                <Route path="/projects" element={<Projects flipTransition={flipTransition} />} />
                <Route path="/feedback" element={<Feedback flipTransition={flipTransition} />} />
              </Routes>
            </motion.div>
          </motion.div>
        </div>

        <NavigationMenu navTransition={navTransition} />

      </Router>
    </>
  );
}

export default App;
